import { useCallback, useMemo } from "react";

import useSWR, { mutate } from "swr";

import type {
  AssessmentRequest,
  AssessmentUpdateRequest,
  CreateRiskParametersSharedFateRequest,
  CreateAssessmentComponentRequest,
  CreateRiskRequestApi,
  RiskParametersResponse,
  AssessmentComponentResponse,
  CreateMultipleAssessmentsRequest,
  HazardType,
  RiskClassEnum,
} from "@/apis/services/HazardService";
import {
  ComponentRegion,
  ReferenceTypeEnum,
  StatusEnum,
} from "@/apis/services/HazardService";
import { sortOnCreatedDate } from "@/components/assessment/constants";
import { TWO_MINUTES } from "@/constants/session";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useUserInfo } from "@/hooks/useUserInfo";
import { hideSharedFateAssessments } from "@/utils/flags/flags-utils";

import { useServices } from "./useServices";

export const useAllAssessments = (refId?: string, isGroup?: boolean) => {
  const { data: userInfo } = useUserInfo();
  const organizationId = userInfo?.user?.organization?.id;
  const { data: flags } = useGetFeatureFlags(organizationId);
  const shouldHideSharedFate = hideSharedFateAssessments(flags);

  const { hazard } = useServices();
  const { data, error } = useSWR(
    refId ? ["getAllAssessments", refId] : null,
    async () => {
      const assessments = await hazard.assessments.getAssessments({
        limit: 1000,
        ref_id: refId ?? "",
        ref_type: isGroup ? ReferenceTypeEnum.GROUP : ReferenceTypeEnum.ASSET,
      });
      return assessments.data;
    },
    { dedupingInterval: TWO_MINUTES }
  );

  const sortedResults = useMemo(() => {
    const sorted = sortOnCreatedDate(data?.results || []);
    if (!shouldHideSharedFate) return sorted;

    // if an assessment has the 'shared-fate' tag on it we want to filter it out of the list
    const filtered = sorted.filter((a) => {
      return a.tags?.every((tag) => tag.toLowerCase() !== "shared-fate");
    });
    return filtered;
  }, [data?.results]);

  return {
    data: sortedResults || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const useAssessmentsByIds = (ids: string[]) => {
  const { hazard } = useServices();
  const { data, isLoading, error } = useSWR(
    ids.length > 0 ? ["useAssessmentsByIds", ...ids] : null,
    async () => {
      const calls = ids.map((id) => {
        return hazard.assessments.getAssessmentById(id);
      });
      const response = await Promise.all(calls);
      return response.map((res) => res.data);
    }
  );

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
  };
};

export const useAssessment = (id?: string) => {
  const { hazard } = useServices();
  const { data, isLoading, error, mutate } = useSWR(
    id ? ["useAssessment", id] : undefined,
    async () => {
      const assessment = await hazard.assessments.getAssessmentById(id!);
      return assessment.data;
    },
    {
      dedupingInterval: 3000,
      refreshInterval: (data) => {
        // when we run an assessment we want to refresh the data every 3 seconds
        if (data?.status === StatusEnum.RUNNING) {
          return 3000;
        }

        return 0;
      },
    }
  );

  const updateCache = useCallback(
    async (status: StatusEnum) => {
      if (!data) return;

      const response = { ...data };
      response.status = status;

      await mutate(response, {
        optimisticData: { ...response },
        rollbackOnError: true,
        revalidate: false,
        populateCache: true,
      });
    },
    [mutate, data]
  );

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
    updateCache,
  };
};

export const useCreateAssessment = () => {
  const { hazard } = useServices();
  const handleCreateAssessment = async (data: AssessmentRequest) => {
    const assessment = await hazard.assessments.createAssessment(data);
    // After creating a new assessment we want to select it but that means we first
    // need to retrieve the latest versions else it will not show up.
    await mutate(["getAllAssessments", data.ref_id]);
    return assessment.data;
  };
  return handleCreateAssessment;
};

export const useDeleteAssessment = () => {
  const { hazard } = useServices();
  const handleDeleteAssessment = async (
    assessmentId: string,
    refId?: string
  ) => {
    await hazard.assessments.deleteAssessment(assessmentId);
    if (refId) await mutate(["getAllAssessments", refId]);
  };
  return handleDeleteAssessment;
};

export const useEditAssessment = () => {
  const { hazard } = useServices();
  const handleEditAssessment = async (
    assessmentId: string,
    data: AssessmentUpdateRequest,
    refId?: string
  ) => {
    const assessment = await hazard.assessments.updateAssessment(
      assessmentId,
      data
    );
    mutate(["useAssessment", assessmentId]);
    if (refId) await mutate(["getAllAssessments", refId]);
    return assessment.data;
  };
  return handleEditAssessment;
};

export const useCreateSharedFateAssessment = () => {
  const { hazard } = useServices();

  const handleCreateSharedFateAssessment = async (
    assessmentId: string,
    params: CreateRiskParametersSharedFateRequest
  ) => {
    const response = await hazard.assessments.createSharedFateRiskParameters(
      assessmentId,
      params
    );
    return response;
  };
  return handleCreateSharedFateAssessment;
};

export const useCreateRiskParameters = () => {
  const { hazard } = useServices();

  const handleCreateRiskParameters = async (
    assessmentId: string,
    params: CreateRiskRequestApi
  ) => {
    const response = await hazard.assessments.createRiskParameters(
      assessmentId,
      params
    );
    return response;
  };
  return handleCreateRiskParameters;
};

export const useCreateAssessmentComponent = () => {
  const { hazard } = useServices();

  const handleCreateAssessmentComponent = async (
    assessment_id: string,
    params: CreateAssessmentComponentRequest
  ) => {
    const response = await hazard.assessments.createAssessmentComponent(
      assessment_id,
      {
        region: ComponentRegion.USA,
      },
      params
    );
    return response;
  };
  return handleCreateAssessmentComponent;
};

export const useInputParameters = (id?: string) => {
  const { hazard } = useServices();
  const { data, error } = useSWR(
    id ? ["useInputParameters", id] : undefined,
    async () => {
      const parameters = await hazard.engines.getInputParameters(
        {
          assessment_id: id!,
        },
        null
      );
      return parameters.data;
    }
  );

  return {
    data: data || undefined,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useAllInputParameters = (query?: {
  hazard_type?: HazardType;
  risk_class?: RiskClassEnum;
}) => {
  const { hazard } = useServices();
  const { data, error } = useSWR(
    ["useAllInputParameters", query?.hazard_type, query?.risk_class],
    async () => {
      const parameters = await hazard.engines.getAllInputParameters(query);
      return parameters.data;
    }
  );

  return {
    data: data || undefined,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useRiskParameters = (assessmentId: string) => {
  const { hazard } = useServices();
  const { data, error } = useSWR(
    assessmentId ? ["getRiskParameters", assessmentId] : null,
    async () => {
      const riskParameters = await hazard.assessments.getRiskParameters(
        assessmentId
      );

      return riskParameters.data.assessment_id
        ? riskParameters.data
        : ({} as RiskParametersResponse);
    }
  );

  const emptyState = useMemo(() => ({} as RiskParametersResponse), []);

  return {
    data: data || emptyState,
    isLoading: !!assessmentId && !error && !data,
    isError: error,
  };
};

export const useAssessmentComponents = (assessmentId: string) => {
  const { hazard } = useServices();
  const { data, error } = useSWR(
    assessmentId ? ["getAssessmentComponents", assessmentId] : null,
    async () => {
      const components = await hazard.assessments.getAssessmentComponents(
        assessmentId
      );

      return components.data ?? [];
    }
  );

  const emptyState = useMemo(() => [] as AssessmentComponentResponse[], []);

  return {
    data: data || emptyState,
    isLoading: !!assessmentId && !error && !data,
    isError: error,
  };
};

export const useSearchAssessments = (query?: any, options?: any) => {
  const { hazard } = useServices();
  const { data, error, isValidating } = useSWR(
    query ? ["useSearchAssessments"] : undefined,
    async () => {
      const parameters = await hazard.assessments.searchAssessments(query);
      return parameters.data;
    },
    { ...options }
  );

  return {
    data: data || undefined,
    isLoading: !error && !data,
    isValidating: isValidating,
    isError: error,
  };
};

export const useCreateMultipleAssessments = () => {
  const { hazard } = useServices();
  const handleCreateMultipleAssessments = async (
    data: CreateMultipleAssessmentsRequest
  ) => {
    const assessment = await hazard.assessments.createMultipleAssessments(data);
    return assessment.data;
  };
  return handleCreateMultipleAssessments;
};

export const useAssessmentsByAssetsIds = (ids: string[]) => {
  const { hazard } = useServices();
  const { data, isLoading, error } = useSWR(
    ids.length > 0 ? ["useAssessmentsByAssetsIds", ...ids] : null,
    async () => {
      const calls = ids.map((id) => {
        return hazard.assessments.getAssessments({
          ref_id: id,
          ref_type: ReferenceTypeEnum.ASSET,
        });
      });
      const response = await Promise.all(calls);
      return response.map((res) => res.data);
    }
  );

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
  };
};
