import {
  ConsequenceType,
  RiskRatingHazardsEnum,
  SubHazardType,
} from "@/apis/services/HazardService";
import { AGGREGATE } from "@/constants/risks";

import {
  capitalizeFirstLetter,
  turnSnakeCaseIntoSpacedText,
} from "./string-utils";

export const getConsequenceDisplayLabel = (value?: string) => {
  if (!value) return "";

  const _value = value.toUpperCase();
  if (_value === ConsequenceType.LIFE_SAFETY) return "Individual life safety";
  if (_value === ConsequenceType.ECONOMIC_LOSS) return "Repair costs";
  if (_value === ConsequenceType.FINANCIAL_LOSS) return "Repair costs";
  if (_value === ConsequenceType.FUNCTIONAL_RECOVERY) return "Downtime";
  if (_value.startsWith("SITEACCESS") || _value.startsWith("UTILITY"))
    return capitalizeFirstLetter(_value.split("_")[1]);

  return turnSnakeCaseIntoSpacedText(value);
};

export const getHazardDisplayLabel = (value: string) => {
  if (!value) return "";

  const _value = value.toLowerCase();
  if (_value === RiskRatingHazardsEnum.HydrologicalFlooding)
    return "Integrated Flooding";
  if (_value === AGGREGATE) return "Multi-hazard risk";

  // TODO move this to the translations file
  if (_value === SubHazardType.EARTHQUAKE_SHAKING.toLowerCase())
    return "Earthquake Shaking";
  if (_value === SubHazardType.TORNADO.toLowerCase()) return "Tornado";
  if (_value === SubHazardType.WILDFIRE.toLowerCase()) return "Wildfire";
  if (_value === SubHazardType.CYCLONIC_WIND.toLowerCase())
    return "Cyclonic Wind";
  if (_value === SubHazardType.INTEGRATED_WIND.toLowerCase())
    return "Integrated Wind";
  if (_value === SubHazardType.INTEGRATED_FLOODING.toLowerCase())
    return "Integrated Flooding";
  if (_value === SubHazardType.GROUNDWATER_FLOODING.toLowerCase())
    return "Groundwater Flooding";
  if (_value === SubHazardType.COASTAL_FLOODING.toLowerCase())
    return "Coastal Flooding";
  if (_value === SubHazardType.RIVERINE_FLOODING.toLowerCase())
    return "Riverine Flooding";
  if (_value === SubHazardType.STORMWATER_FLOODING.toLowerCase())
    return "Stormwater Flooding";

  return turnSnakeCaseIntoSpacedText(value, true, false, 10);
};
