import { Typography } from "@mui/material";
import Image from "next/image";

export const AssessmentRatingTooltip = () => {
  return (
    <>
      <Typography>
        The repair cost risk rating shown here is generated based on the
        calculated average annual loss (AAL) and AAL table corresponding to the
        risk tolerance level assigned to this asset.
        <br /> The default AAL table for a risk neutral asset is shown below.
        <br /> The actual rating may vary depending on the risk tolerance
        assigned to the asset.
      </Typography>
      <Image
        src="/consequenceImages/repair-cost/repair-cost.png"
        alt="repair cost image"
        width={400}
        height={237}
      />
    </>
  );
};
