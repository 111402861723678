import { useState } from "react";

import { Chip } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

import type {
  AssessmentOutputResponse,
  RiskRatingEnum,
} from "@/apis/services/HazardService";
import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import { ChipRatingBarOptions } from "@/components/common/ChipRatingBarOptions";
import { ChipRatingBarTooltip } from "@/components/common/ChipRatingBarTooltip";
import { DefaultLoader } from "@/components/common/loading/DefaultLoader";
import { RATING_TO_VALUE } from "@/components/overview/asset/AssetOverview/asset-overview-utils";
import { getRiskRatingColorsByLevel } from "@/components/risk-ratings/ClassOneRiskRatings";
import type { RatingData } from "@/components/risk-ratings/risk-ratings-utils";
import { useUpdateAssessmentOutput } from "@/hooks/useAssessmentOutputs";

interface Editable {
  isEditable?: boolean;
  output?: AssessmentOutputResponse;
}
interface Props {
  data?: string;
  subRisk?: RatingData;
  consequence?: ConsequenceUnion;
  size?: "small" | "medium";
  override?: any;
  editable?: Editable;
  isTemplate?: boolean;
}

export const ChipRatingBar = (props: Props) => {
  const { data, subRisk, consequence, size, override, editable, isTemplate } =
    props;
  const { isEditable, output } = editable ?? {};

  const handleUpdateAssessmentOutput = useUpdateAssessmentOutput();

  const { t } = useTranslation();
  const theme = useTheme();

  const rating = data ?? subRisk?.data ?? null;
  const riskInfo = rating ? getRiskRatingColorsByLevel(rating) : null;
  const backgroundColor = riskInfo?.color ?? theme.palette["Not Assessed"].main;

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isTemplate) return;
    setShowTooltip(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setShowTooltip(false);
  };

  const onShowOptions = () => {
    setShowOptions(true);
  };

  const onHideOptions = () => {
    setShowOptions(false);
  };

  const onChangeOption = async (rating: RiskRatingEnum) => {
    onHideOptions();

    if (!output) return;

    try {
      setLoading(true);
      await handleUpdateAssessmentOutput(
        output.assessment_id,
        output.output_id,
        { value: RATING_TO_VALUE[rating] }
      );
    } catch (error) {
      console.error(`Error updating assessment output: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <DefaultLoader sx={{ width: 140 }} height="32px" />}
      {!loading && (
        <Chip
          aria-owns={showTooltip ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => handlePopoverOpen(e)}
          onMouseLeave={handlePopoverClose}
          data-test={`risk-rating-pill-${t(consequence ?? "").toLowerCase()}`}
          label={(data || subRisk?.data) ?? "Not Assessed"}
          sx={{
            backgroundColor: backgroundColor,
            color: "#FFFFFF",
            width: 140,
            fontSize: "0.8rem",
            borderRadius: 1.5,
            ...override,
          }}
          size={size}
          onClick={isEditable ? onShowOptions : undefined}
        />
      )}

      {riskInfo && (
        <ChipRatingBarTooltip
          show={showTooltip}
          onClose={handlePopoverClose}
          anchor={anchorEl}
          data={data}
          consequence={consequence}
          subRisk={subRisk}
        />
      )}

      {isEditable && (
        <ChipRatingBarOptions
          open={showOptions}
          onClose={onHideOptions}
          anchor={anchorEl}
          onChange={onChangeOption}
        />
      )}
    </>
  );
};
