import React from "react";

import type { SxProps } from "@mui/material";
import { Skeleton } from "@mui/material";

export interface DefaultLoaderParameters {
  height?: number | string;
  sx?: SxProps;
}

export const DefaultLoader = (props: DefaultLoaderParameters) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={props.height ?? 280}
      sx={{ ...props.sx }}
    />
  );
};
