import { ConsequenceType } from "@/apis/services/HazardService";

export const PREVIEW_CONSEQUENCES: ConsequenceType[] = [
  ConsequenceType.ECONOMIC_LOSS.toLowerCase() as ConsequenceType,
  ConsequenceType.DOWNTIME.toLowerCase() as ConsequenceType,
  ConsequenceType.LIFE_SAFETY.toLowerCase() as ConsequenceType,
];

export const CONSEQUENCE_PREFIX: any = {
  0: "first_consequence",
  1: "second_consequence",
  2: "third_consequence",
  3: "fourth_consequence",
};

export const HAZARD_PREFIX: any = {
  0: "first_hazard",
  1: "second_hazard",
  2: "third_hazard",
  3: "fourth_hazard",
  4: "fifth_hazard",
  5: "sixth_hazard",
};

export const ASSESSMENT_PREFIX: any = {
  0: "first_assessment",
  1: "second_assessment",
  2: "third_assessment",
  3: "fourth_assessment",
};

export const INTENSITY_MEASURE_PREFIX: any = {
  0: "first_intensity_measure",
  1: "second_intensity_measure",
  2: "third_intensity_measure",
  3: "fourth_intensity_measure",
};

export const RETURN_PERIOD_PREFIX: any = {
  0: "first_return_period",
  1: "second_return_period",
  2: "third_return_period",
  3: "fourth_return_period",
};
