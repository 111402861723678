import type { Flag } from "@/hooks/useFlags";

const getFlag = (flags: Flag[], name: string) => {
  return flags.find((f) => f.name === name);
};

export const isFlagEnabled = (flags: Flag[], name: string) => {
  const flag = getFlag(flags, name);
  return !!flag?.enabled;
};

export const getFlagAttribute = (flags: Flag[], name: string, key: string) => {
  const flag = getFlag(flags, name);
  return flag?.attributes?.[key]?.value;
};

/** This is a very specific feature flag to hide assessments with a 'shared-fate' tag */
export const hideSharedFateAssessments = (flags: Flag[]) => {
  const hideSharedFate = getFlag(flags, "hideSharedFateAssessments");
  return !!hideSharedFate?.enabled;
};
