import { RiskRatingEnum } from "@/apis/services/HazardService";

interface ToValue {
  [key: string]: number;
}

export const RATING_TO_VALUE: ToValue = {
  [RiskRatingEnum.Catastrophic]: 9,
  [RiskRatingEnum.VeryHigh]: 8,
  [RiskRatingEnum.HighVeryHigh]: 7,
  [RiskRatingEnum.High]: 6,
  [RiskRatingEnum.MediumHigh]: 5,
  [RiskRatingEnum.Medium]: 4,
  [RiskRatingEnum.LowMedium]: 3,
  [RiskRatingEnum.Low]: 2,
  [RiskRatingEnum.VeryLow]: 1,
  [RiskRatingEnum.Negligible]: 0,
  [RiskRatingEnum.NotAssessed]: -1,
  [RiskRatingEnum.Plausible]: -2,
};

interface ToRating {
  [key: number]: RiskRatingEnum;
}

export const VALUE_TO_RATING: ToRating = {
  9: RiskRatingEnum.Catastrophic,
  8: RiskRatingEnum.VeryHigh,
  7: RiskRatingEnum.HighVeryHigh,
  6: RiskRatingEnum.High,
  5: RiskRatingEnum.MediumHigh,
  4: RiskRatingEnum.Medium,
  3: RiskRatingEnum.LowMedium,
  2: RiskRatingEnum.Low,
  1: RiskRatingEnum.VeryLow,
  0: RiskRatingEnum.Negligible,
  [-1]: RiskRatingEnum.NotAssessed,
  [-2]: RiskRatingEnum.Plausible,
};
