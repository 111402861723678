import { RiskClassEnum } from "@/apis/services/HazardService";

export const POLL_TIMEOUT_IN_MS = 20000;
export const POLL_TIMEOUT_IN_MS_FOR_RASTER = 2000;

export const FUNCTIONAL_DOWNTIME_GRAPH_COLORS = [
  "#539684",
  "#AE1919",
  "#D39932",
  "#1395BA",
  "#4F0B4E",
  "#BF3B23",
];

export function sortOnCreatedDate<T>(input: T[]): T[] {
  return input?.sort(
    (a: any, b: any) =>
      Date.parse(b.created_date || "January 1, 1970") -
      Date.parse(a.created_date || "0")
  );
}

export const RISK_CLASS_LABELS: any = {
  [RiskClassEnum.Value0]: "Class 0 - Prescreening",
  [RiskClassEnum.Value1]: "Class 1 - Risk Screening",
  [RiskClassEnum.Value2]: "Class 2 - Enhanced Risk Screening",
  [RiskClassEnum.Value3]: "Class 3 - Site Specific Risk Modelling",
  [RiskClassEnum.Value4]: "Class 4 - Detailed Site Specific Risk Modelling",
};

export const SUPPORTED_PERCENTILES = [0, 50];
