import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";

import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import {
  convertConsequence,
  convertLikelihood,
  getRiskRatingColorsByLevel,
} from "@/components/risk-ratings/ClassOneRiskRatings";
import type { RatingData } from "@/components/risk-ratings/risk-ratings-utils";
import { getDescriptionByConsequence } from "@/constants/rating-description";
import { getConsequenceDisplayLabel } from "@/utils/display-label-utils";

interface Props {
  show: boolean;
  onClose: () => void;
  anchor?: HTMLElement;
  data?: string;
  subRisk?: RatingData;
  consequence?: ConsequenceUnion;
}

export const ChipRatingBarTooltip = (props: Props) => {
  const { show, onClose, anchor, data, subRisk, consequence } = props;

  const { pathname } = useRouter();
  const isAssessment = pathname.includes("risk-metrics");

  const rating = data ?? subRisk?.data ?? null;
  const cons = consequence ?? subRisk?.column;
  const riskInfo = rating ? getRiskRatingColorsByLevel(rating) : null;

  const options: any = { rating: rating, consequence: cons, isAssessment };
  const description = getDescriptionByConsequence(options);

  const returnPeriod = convertLikelihood(subRisk?.column, subRisk?.likelihood);
  const magnitude = convertConsequence(subRisk?.column, subRisk?.consequence);
  const repairCost = returnPeriod && magnitude && (
    <>
      <b>{getConsequenceDisplayLabel(cons)}</b> of <b>{magnitude}</b> may occur
      every <b>{returnPeriod}</b>.
    </>
  );

  const confidence = subRisk?.confidence;
  const riskClass = confidence && (
    <>
      A <b>Class {confidence}</b> risk assessment was performed.
    </>
  );

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={show}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      disableRestoreFocus
      disableScrollLock
    >
      <Typography component="div" sx={{ p: 1, maxWidth: 500 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: riskInfo?.color || "rgb(216,216,216)" }}
                  textAlign="center"
                >
                  {rating}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {riskClass && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="body2">{riskClass}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {repairCost && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="body2">{repairCost}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {description && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="body2">{description}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Typography>
    </Popover>
  );
};
