import { mutate } from "swr";

import type {
  ConsequenceType,
  DataRecordModelOutput,
  UpdateAssessmentOutputRequest,
} from "@/apis/services/HazardService";
import { useServices } from "@/hooks/useServices";

export const useCreateAssessmentOutputs = () => {
  const { hazard } = useServices();

  const handleCreateAssessmentOutputs = async (
    assessmentId: string,
    data: DataRecordModelOutput[]
  ) => {
    const response = await hazard.assessments.createAssessmentOutputs(
      assessmentId,
      data
    );

    await mutate(["useAssessment", assessmentId]);
    return response.data;
  };

  return handleCreateAssessmentOutputs;
};

export const useUpdateAssessmentOutput = () => {
  const { hazard } = useServices();

  const handleUpdateAssessmentOutput = async (
    assessmentId: string,
    outputId: string,
    data: UpdateAssessmentOutputRequest
  ) => {
    const response = await hazard.assessments.updateAssessmentOutput(
      assessmentId,
      outputId,
      data
    );

    await mutate(["useAssessment", assessmentId]);
    return response.data;
  };

  return handleUpdateAssessmentOutput;
};

export const useDeleteAssessmentOutput = () => {
  const { hazard } = useServices();

  const handleDeleteAssessmentOutput = async (
    assessmentId: string,
    outputId: string
  ) => {
    const response = await hazard.assessments.deleteAssessmentOutput(
      assessmentId,
      outputId
    );

    await mutate(["useAssessment", assessmentId]);
    return response.data;
  };

  return handleDeleteAssessmentOutput;
};

export const useDeleteAssessmentOutputs = () => {
  const { hazard } = useServices();

  const handleDeleteAssessmentOutput = async (
    assessmentId: string,
    type: ConsequenceType
  ) => {
    const response = await hazard.assessments.deleteAssessmentOutputs(
      assessmentId,
      { data_key: type }
    );

    await mutate(["useAssessment", assessmentId]);
    return response.data;
  };

  return handleDeleteAssessmentOutput;
};
