import type { RiskRatingEnum } from "@/apis/services/HazardService";
import type {
  ConsequenceType,
  RiskRatingConsequenceType,
} from "@/apis/services/HazardService";
import { AssessmentRatingTooltip } from "@/components/common/tooltips/AssessmentRatingTooltip";
import { turnStringIntoLowerSnakeCase } from "@/utils/string-utils";

interface Options {
  rating?: RiskRatingEnum;
  consequence: ConsequenceType | RiskRatingConsequenceType;
  isAssessment?: boolean;
}

interface Lookup {
  [key: string]: Value;
}

interface Value {
  likelihood: string;
}

const DOWNTIME: Lookup = {
  catastrophic: { likelihood: "greater than 3000 days" },
  very_high: { likelihood: "greater than 3000 days" },
  high_very_high: { likelihood: "between 300 and 3000 days" },
  high: { likelihood: "between 30 and 300 days" },
  medium_high: { likelihood: "between 3 and 30 days" },
  medium: { likelihood: "between 0.3 and 3 days" },
  low_medium: { likelihood: "between 0.03 and 0.3 days" },
  low: { likelihood: "between 0.003 and 0.03 days" },
  very_low: { likelihood: "less than 0.003 days" },
  negligible: { likelihood: "less than 0.003 days" },
};

const REPAIR_COST: Lookup = {
  catastrophic: { likelihood: "greater than 170%" },
  very_high: { likelihood: "between 50% and 170%" },
  high_very_high: { likelihood: "between 17% and 50%" },
  high: { likelihood: "between 5% and 17%" },
  medium_high: { likelihood: "between 1.7% and 5%" },
  medium: { likelihood: "between 0.5% and 1.7%" },
  low_medium: { likelihood: "between 0.17% and 0.5%" },
  low: { likelihood: "between 0.05% and 0.17%" },
  very_low: { likelihood: "less than 0.05%" },
  negligible: { likelihood: "less than 0.05%" },
};

const FATALITIES: Lookup = {
  catastrophic: { likelihood: "greater than 10%" },
  very_high: { likelihood: "between 3% and 10%" },
  high_very_high: { likelihood: "between 1% and 3%" },
  high: { likelihood: "between 0.3% and 1%" },
  medium_high: { likelihood: "between 0.1% and 0.3%" },
  medium: { likelihood: "between 0.03% and 0.1%" },
  low_medium: { likelihood: "between 0.01% and 0.03%" },
  low: { likelihood: "less than 0.01%" },
  very_low: { likelihood: "less than 0.01%" },
  negligible: { likelihood: "less than 0.01%" },
};

interface ConsequenceLookup {
  [key: string]: Lookup;
}

const CONSEQUENCES_LOOKUP: ConsequenceLookup = {
  FUNCTIONAL_RECOVERY: DOWNTIME,
  DOWNTIME: DOWNTIME,
  FULL_RECOVERY: DOWNTIME,
  FINANCIAL_LOSS: REPAIR_COST,
  ECONOMIC_LOSS: REPAIR_COST,
  REPAIR_COST: REPAIR_COST,
  INVENTORY_LOSS: REPAIR_COST,
  DAMAGE: REPAIR_COST,
  LIFE_SAFETY: FATALITIES,
  AGGREGATE_LIFE_SAFETY: FATALITIES,
  // TODO No descriptions yet for these
  // FATALITIES: FATALITIES,
  // INJURIES: FATALITIES,
  // HEALTH_AND_WELLNESS,
  // SEVERE_WEATHER_WARNING,
};

const getConsequenceDescription = (
  consequence: string,
  val: Value,
  isAssessment?: boolean
) => {
  const { likelihood } = val;
  const downtimes = ["FUNCTIONAL_RECOVERY", "DOWNTIME", "FULL_RECOVERY"];
  if (downtimes.includes(consequence)) {
    return (
      <>
        On average, the expected cumulative downtime is <b>{likelihood}</b> over
        the next <b>10 years</b>.
      </>
    );
  }

  const costs = [
    "FINANCIAL_LOSS",
    "ECONOMIC_LOSS",
    "REPAIR_COST",
    "INVENTORY_LOSS",
    "DAMAGE",
  ];
  if (costs.includes(consequence)) {
    if (isAssessment) return <AssessmentRatingTooltip />;

    return (
      <>
        On average, the expected cumulative costs to repair damage is{" "}
        <b>{likelihood}</b> of the asset replacement value over the next{" "}
        <b>10 years</b>.
      </>
    );
  }

  const safety = ["LIFE_SAFETY", "AGGREGATE_LIFE_SAFETY"];
  if (safety.includes(consequence)) {
    return (
      <>
        On average, the likelihood of an event causing a fatality for an
        individual is <b>{likelihood}</b> over the next <b>10 years</b>.
      </>
    );
  }
  return "";
};

export const getDescriptionByConsequence = (options: Options) => {
  const { rating, consequence, isAssessment } = options;
  const _consequence = consequence?.toUpperCase() ?? "";

  const risk = turnStringIntoLowerSnakeCase(rating ?? "");
  if (risk === "not_assessed") return "";

  const consequenceLookup = CONSEQUENCES_LOOKUP[_consequence];
  if (!consequenceLookup) return "";

  const value = consequenceLookup[risk];
  if (!value) return "";

  return getConsequenceDescription(_consequence, value, isAssessment);
};
